const SET_REPORTS = 'SET_REPORTS';
const ADD_REPORT = 'ADD_REPORT';
const REPORT_FETCHED = 'REPORT_FETCHED';
const REPORT_UPDATED = 'REPORT_UPDATED';
const REPORT_DELETED = 'REPORT_DELETED';
const REPORT_DOWNLOAD = 'REPORT_DOWNLOAD';
const REPORT_EXPORT_LIST = 'REPORT_EXPORT_LIST';
const REPORT_ERRORS = 'REPORT_ERRORS';
const REPORT_LOADING = 'REPORT_LOADING';
const NEW_REPORT_FETCHED = 'NEW_REPORT_FETCHED';
const REPORT_PROPERTIES_OPTS_LOADING = 'REPORT_PROPERTIES_OPTS_LOADING';
const REPORT_PROPERTIES_OPTS_FETCHED = 'REPORT_PROPERTIES_OPTS_FETCHED';
const REPORT_PROPERTIES_OPTS_ERRORS = 'REPORT_PROPERTIES_OPTS_ERRORS';
const REPORT_FILTER_OPTS_LOADING = 'REPORT_FILTER_OPTS_LOADING';
const REPORT_FILTER_OPTS_FETCHED = 'REPORT_FILTER_OPTS_FETCHED';
const REPORT_FILTER_OPTS_ERRORS = 'REPORT_FILTER_OPTS_ERRORS';
const REPORT_PROJECTED_LOADING = 'REPORT_PROJECTED_LOADING';
const REPORT_PROJECTED_FETCHED = 'REPORT_PROJECTED_FETCHED';
const REPORT_PROJECTED_ERRORS = 'REPORT_PROJECTED_ERRORS';
const REPORT_CLEAN_PAGE = 'REPORT_CLEAN_PAGE';
const REPORT_UPLOAD_LOADING = 'REPORT_UPLOAD_LOADING';
const REPORT_UPLOAD_FETCHED = 'REPORT_UPLOAD_FETCHED';
const REPORT_UPLOAD_ERRORS = 'REPORT_UPLOAD_ERRORS;'
const REPORT_CLONED = 'REPORT_CLONED';
//const REPORT_MASSIVE_UPDATE = 'REPORT_MASSIVE_UPDATE';

export default {
    SET_REPORTS,
    ADD_REPORT,
    REPORT_FETCHED,
    REPORT_UPDATED,
    REPORT_DELETED,
    REPORT_DOWNLOAD,
    REPORT_EXPORT_LIST,
    REPORT_ERRORS,
    REPORT_LOADING,
    NEW_REPORT_FETCHED,
    REPORT_PROPERTIES_OPTS_LOADING,
    REPORT_PROPERTIES_OPTS_FETCHED,
    REPORT_PROPERTIES_OPTS_ERRORS,
    REPORT_FILTER_OPTS_LOADING,
    REPORT_FILTER_OPTS_FETCHED,
    REPORT_FILTER_OPTS_ERRORS,
    REPORT_PROJECTED_LOADING,
    REPORT_PROJECTED_FETCHED,
    REPORT_PROJECTED_ERRORS,
    REPORT_CLEAN_PAGE,
    REPORT_UPLOAD_LOADING,
    REPORT_UPLOAD_FETCHED,
    REPORT_UPLOAD_ERRORS,
    REPORT_CLONED,
    //REPORT_MASSIVE_UPDATE
}



import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import { createBrowserHistory } from 'history'
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router'
import reducer from './store/reducer';
import { connectRouter } from 'connected-react-router'
import { authenticationReducer } from './Admin/Authentication/SignIn/store/reducers';
import { userReducer } from './Admin/UserCrud/user/reducers';
import { reportReducer } from './Admin/ReportCrud/report/reducers';
import { additionalInfoReducer } from './Admin/AdditionalInfoCrud/additionalInfo/reducers';
import { controlPanelReducer } from './Admin/ControlPanel/controlPanel/reducers';

export const history = createBrowserHistory()

export default function configureStore(preloadedState) {
    const reducers = {
        main: reducer,
        authentication: authenticationReducer,
        user: userReducer,
        report: reportReducer,
        additionalInfo: additionalInfoReducer,
        controlPanel: controlPanelReducer
    };

    const createRootReducer = (history) => combineReducers({
        ...reducers,
        router: connectRouter(history),
    })

    const middleware = [
        thunk,
        routerMiddleware(history)
    ];

    // In development, use the browser's Redux dev tools extension if installed
    const enhancers = [];
    const isDevelopment = process.env.NODE_ENV === 'development';
    if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
        enhancers.push(window.devToolsExtension());
    }

    const store = createStore(
        createRootReducer(history), // root reducer with router state
        preloadedState,
        compose(applyMiddleware(...middleware), ...enhancers)
    )

    return store
}


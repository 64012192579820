const CONTROL_PANEL_ERRORS = 'CONTROL_PANEL_ERRORS';
const CONTROL_PANEL_LOADING = 'CONTROL_PANEL_LOADING';
const CONTROL_PANEL_SCHEDULE_JOB = 'CONTROL_PANEL_SCHEDULE_JOB';
const CONTROL_PANEL_ALERT_PENDING_REPORTS = 'CONTROL_PANEL_ALERT_PENDING_REPORTS';
const CONTROL_PANEL_AUTO_SITUATION = 'CONTROL_PANEL_AUTO_SITUATION';

export default {
    CONTROL_PANEL_ERRORS,
    CONTROL_PANEL_LOADING,
    CONTROL_PANEL_SCHEDULE_JOB,
    CONTROL_PANEL_ALERT_PENDING_REPORTS,
    CONTROL_PANEL_AUTO_SITUATION
}



import types from './types.js';

const initialState = { sortFields: '_id desc', results:[]};

export function additionalInfoReducer(state = initialState, action = {}) {
    switch (action.type) {
        case types.ADD_ADDITIONAL_INFO:
            //TODO:funciona? no hay que actualizar la página?
            return {
                ...state,
                results: [
                    ...state.results,
                    action.additionalInfo
                ],
                isLoading: undefined
            }
        case types.ADDITIONAL_INFO_DELETED:
            return {
                ...state,
                results: state.results.filter(item => item._id !== action._id),
                isLoading: undefined
            }
        case types.ADDITIONAL_INFO_UPDATED:
            return {
                ...state,
                errors: undefined,
                results: state.results.map(item => {
                    if (item._id === action.additionalInfo._id) return action.additionalInfo;
                    return item;
                }),
                isLoading: undefined
            }
        case types.ADDITIONAL_INFO_FETCHED:
        
            if (!state.results) {
                return {
                    ...state,
                    results: [
                        action.additionalInfo
                    ],
                    isLoading: undefined
                }
            }
            else {
                const index = state.results.findIndex(item => item._id === action.additionalInfo._id);
                if (index > -1) {
                    return {
                        ...state,
                        results: state.results.map(item => {
                            if (item._id === action.additionalInfo._id) return action.additionalInfo;
                            return item;
                        }),
                        isLoading: undefined
                    }
                } else {
                    return {
                        ...state,
                        results: [
                            ...state.results,
                            action.additionalInfo
                        ],
                        isLoading: undefined
                    }
                }
            }
        case types.SET_ADDITIONAL_INFOS:
            return action.page;
        case types.ADDITIONAL_INFO_FOR_FETCHED:
            return {
                results: action.results
            }
        case types.PASSWORD_UPDATED:
        case types.ADDITIONAL_INFO_PROPERTIES_OPTS_FETCHED:
            return {
                ...state,
                isLoading: false
            }
        case types.ADDITIONAL_INFO_ERRORS:
        case types.ADDITIONAL_INFO_FOR_ERRORS:
        case types.ADDITIONAL_INFO_PROPERTIES_OPTS_ERRORS:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    ...action.errors
                },
                isLoading: undefined
            }
        case types.ADDITIONAL_INFO_FOR_LOADING:
            return {
                ...state,
                results: [],
                isLoading: true
            }
        case types.ADDITIONAL_INFO_LOADING:
        case types.ADDITIONAL_INFO_PROPERTIES_OPTS_LOADING:
            return {
                ...state,
                isLoading: true
            }
        default:
            return state;
    }
}
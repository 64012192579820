const SET_USERS = 'SET_USERS';
const ADD_USER = 'ADD_USER';
const USER_FETCHED = 'USER_FETCHED';
const USER_UPDATED = 'USER_UPDATED';
const USER_DELETED = 'USER_DELETED';
const USER_ERRORS = 'USER_ERRORS';
const USER_LOADING = 'USER_LOADING';
const PASSWORD_UPDATED = 'PASSWORD_UPDATED';
const USER_PROPERTIES_OPTS_LOADING = 'USER_PROPERTIES_OPTS_LOADING';
const USER_PROPERTIES_OPTS_FETCHED = 'USER_PROPERTIES_OPTS_FETCHED';
const USER_PROPERTIES_OPTS_ERRORS = 'USER_PROPERTIES_OPTS_ERRORS';

export default {
    SET_USERS,
    ADD_USER,
    USER_FETCHED,
    USER_UPDATED,
    USER_DELETED,
    USER_ERRORS,
    USER_LOADING,
    PASSWORD_UPDATED,
    USER_PROPERTIES_OPTS_LOADING,
    USER_PROPERTIES_OPTS_FETCHED,
    USER_PROPERTIES_OPTS_ERRORS
}



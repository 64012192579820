import types from './types.js';

const INIT_STATE = {
    user: JSON.parse(localStorage.getItem('user'))
};

export function authenticationReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case types.LOGIN_REQUEST:
            return {
                ...state,
                loggingIn: true,
                user: action.user
            };
        case types.USER_STATUS_SUCCESS:
        case types.LOGIN_SUCCESS:
            return {
                ...state,
                loggedIn: true,
                user: action.user,
                errors: undefined
            };
        case types.USER_STATUS_REQUEST:
            return {
                ...state,
                loggingIn: true,
            }
        case types.MENU_REQUEST:
            return {
                ...state,
                menuItems: null,
                isLoading: true
            };
        case types.MENU_SUCCESS:
            return {
                ...state,
                menuItems: action.items,
                isLoading: false
            };
        case types.USER_STATUS_FAILURE:
        case types.LOGIN_FAILURE:
        case types.LOGOUT:
            return {
                user: null,
                loggingIn: false,
                errors: action.errors
            };
        case types.MENU_FAILURE:
            return {
                ...state,
                menuItems: [],
                isLoading: false
            };
        default:
            return state
    }
}
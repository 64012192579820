import types from './types.js';

const initialState = {};

export function controlPanelReducer(state = initialState, action = {}) {
    switch (action.type) {
        case types.CONTROL_PANEL_SCHEDULE_JOB:
        case types.CONTROL_PANEL_ALERT_PENDING_REPORTS:
        case types.CONTROL_PANEL_AUTO_SITUATION:
            return {
                ...state,
                errors: undefined,
                isLoading: false
            }
        case types.CONTROL_PANEL_DESCS_ERRORS:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    ...action.errors
                },
                isLoading: undefined
            }
        case types.CONTROL_PANEL_LOADING:
            return {
                ...state,
                isLoading: true
            }
        default:
            return state;
    }
}
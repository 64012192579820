import types from './types.js';

const initialState = { sortFields: '_id desc', results: [] };

export function reportReducer(state = initialState, action = {}) {
    switch (action.type) {
        case types.ADD_REPORT:
            //TODO:funciona? no hay que actualizar la página?
            return {
                ...state,
                //newReport: undefined,
                errors: undefined,
                results: [
                    action.report,
                    ...state.results
                ],
                isLoading: undefined
            }
        case types.REPORT_DELETED:
            return {
                ...state,
                results: state.results.filter(item => item._id !== action._id),
                isLoading: undefined
            }
        case types.REPORT_UPDATED:
            return {
                ...state,
                errors: undefined,
                results: state.results.map(item => {
                    if (item._id === action.report._id) return action.report;
                    return item;
                }),
                isLoading: undefined
            }
        case types.REPORT_FETCHED:
            if (!state.results) {
                return {
                    ...state,
                    results: [
                        action.report
                    ],
                    isLoading: undefined
                }
            }
            else {
                const index = state.results.findIndex(item => item._id === action.report._id);
                if (index > -1) {
                    return {
                        ...state,
                        results: state.results.map(item => {
                            if (item._id === action.report._id) return action.report;
                            return item;
                        }),
                        isLoading: undefined
                    }
                } else {
                    return {
                        ...state,
                        results: [
                            ...state.results,
                            action.report
                        ],
                        isLoading: undefined
                    }
                }
            }
        case types.SET_REPORTS:
            if (action.page.name) {
                let result = {
                    ...state,                    
                    isLoading: undefined,
                    errors: undefined
                }
                result[action.page.name] = action.page; //we use a special page for this results
                return result;
            }
            else {
                return {
                    ...state,
                    ...action.page,
                    isLoading: undefined,
                    errors: undefined
                }
            }
        case types.PASSWORD_UPDATED:
        case types.REPORT_PROPERTIES_OPTS_FETCHED:
        case types.REPORT_PROJECTED_FETCHED:
        case types.REPORT_UPLOAD_FETCHED:
            return {
                ...state,
                isLoading: false
            }
        case types.REPORT_FILTER_OPTS_FETCHED:
            if ((!action.filter) || state.filter && state.filter._id === action.filter._id) {
                return {
                    ...state,
                    isLoading: false
                }
            }
            else {
                return {
                    ...state,
                    filter: action.filter,
                    isLoading: false
                }
            }
        case types.REPORT_ERRORS:
        case types.REPORT_PROPERTIES_OPTS_ERRORS:
        case types.REPORT_FILTER_OPTS_ERRORS:
        case types.REPORT_PROJECTED_ERRORS:
        case types.REPORT_UPLOAD_ERRORS:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    ...action.errors
                },
                isLoading: undefined
            }
        case types.REPORT_LOADING:
            if (action.filter) {
                return {
                    ...state,
                    filter: action.filter,
                    isLoading: true
                }
            }
            //else...
        case types.REPORT_PROPERTIES_OPTS_LOADING:
        case types.REPORT_FILTER_OPTS_LOADING:
        case types.REPORT_PROJECTED_LOADING:
        case types.REPORT_UPLOAD_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case types.REPORT_DOWNLOAD:
        case types.REPORT_EXPORT_LIST:
            return {
                ...state,
                isLoading: false
            }
        case types.NEW_REPORT_FETCHED:
            return {
                ...state,
                errors: undefined,
                newReport: action.report,
                isLoading: false
            }
        case types.REPORT_CLEAN_PAGE:
            return {
                ...initialState
            }
        case types.REPORT_CLONED:
            return {
                ...state,
                errors: undefined,
                newReport: action.report,
                isLoading: false
            }
        default:
            return state;
    }
}
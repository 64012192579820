const SET_ADDITIONAL_INFOS = 'SET_ADDITIONAL_INFOS';
const ADD_ADDITIONAL_INFO = 'ADD_ADDITIONAL_INFO';
const ADDITIONAL_INFO_FETCHED = 'ADDITIONAL_INFO_FETCHED';
const ADDITIONAL_INFO_UPDATED = 'ADDITIONAL_INFO_UPDATED';
const ADDITIONAL_INFO_DELETED = 'ADDITIONAL_INFO_DELETED';
const ADDITIONAL_INFO_ERRORS = 'ADDITIONAL_INFO_ERRORS';
const ADDITIONAL_INFO_LOADING = 'ADDITIONAL_INFO_LOADING';
const ADDITIONAL_INFO_PROPERTIES_OPTS_LOADING = 'ADDITIONAL_INFO_PROPERTIES_OPTS_LOADING';
const ADDITIONAL_INFO_PROPERTIES_OPTS_FETCHED = 'ADDITIONAL_INFO_PROPERTIES_OPTS_FETCHED';
const ADDITIONAL_INFO_PROPERTIES_OPTS_ERRORS = 'ADDITIONAL_INFO_PROPERTIES_OPTS_ERRORS';
const ADDITIONAL_INFO_FOR_LOADING = 'ADDITIONAL_INFO_FOR_LOADING';
const ADDITIONAL_INFO_FOR_FETCHED = 'ADDITIONAL_INFO_FOR_FETCHED';
const ADDITIONAL_INFO_FOR_ERRORS = 'ADDITIONAL_INFO_FOR_ERRORS';

export default {
    SET_ADDITIONAL_INFOS,
    ADD_ADDITIONAL_INFO,
    ADDITIONAL_INFO_FETCHED,
    ADDITIONAL_INFO_UPDATED,
    ADDITIONAL_INFO_DELETED,
    ADDITIONAL_INFO_ERRORS,
    ADDITIONAL_INFO_LOADING,
    ADDITIONAL_INFO_PROPERTIES_OPTS_LOADING,
    ADDITIONAL_INFO_PROPERTIES_OPTS_FETCHED,
    ADDITIONAL_INFO_PROPERTIES_OPTS_ERRORS,
    ADDITIONAL_INFO_FOR_LOADING,
    ADDITIONAL_INFO_FOR_FETCHED,
    ADDITIONAL_INFO_FOR_ERRORS
}



import React from 'react';

const SignUp = React.lazy(() => import('./Admin/Authentication/SignUp/SignUp'));
const Signin = React.lazy(() => import('./Admin/Authentication/SignIn/SignIn'));

const route = [
    { path: '/auth/signup', exact: true, name: 'Signup 1', component: SignUp, public: true },
    { path: '/auth/signin', exact: true, name: 'Signin 1', component: Signin, public: true }
];

export default route;
const LOGIN_REQUEST = 'USERS_LOGIN_REQUEST';
const LOGIN_SUCCESS = 'USERS_LOGIN_SUCCESS';
const LOGIN_FAILURE = 'USERS_LOGIN_FAILURE';
const LOGOUT = 'USERS_LOGOUT';
const USER_STATUS_REQUEST = 'USERS_STATUS_REQUEST';
const USER_STATUS_SUCCESS = 'USERS_STATUS_SUCCESS';
const USER_STATUS_FAILURE = 'USERS_STATUS_FAILURE';
const MENU_REQUEST = 'MENU_REQUEST';
const MENU_SUCCESS = 'MENU_SUCCESS';
const MENU_FAILURE = 'MENU_FAILURE';

export default {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LOGOUT,
    USER_STATUS_REQUEST,
    USER_STATUS_SUCCESS,
    USER_STATUS_FAILURE,
    MENU_REQUEST,
    MENU_SUCCESS,
    MENU_FAILURE
}
import types from './types.js';

const initialState = { sortFields: '_id'};

export function userReducer(state = initialState, action = {}) {
    switch (action.type) {
        case types.ADD_USER:
            //TODO:funciona? no hay que actualizar la página?
            return {
                ...state,
                results: [
                    ...state.results,
                    action.user
                ],
                isLoading: undefined
            }
        case types.USER_DELETED:
            return {
                ...state,
                results: state.results.filter(item => item._id !== action._id),
                isLoading: undefined
            }
        case types.USER_UPDATED:
            return {
                ...state,
                errors: undefined,
                results: state.results.map(item => {
                    if (item._id === action.user._id) return action.user;
                    return item;
                }),
                isLoading: undefined
            }
        case types.USER_FETCHED:
            if (!state.results) {
                return {
                    ...state,
                    results: [
                        action.user
                    ],
                    isLoading: undefined
                }
            }
            else {
                const index = state.results.findIndex(item => item._id === action.user._id);
                if (index > -1) {
                    return {
                        ...state,
                        results: state.results.map(item => {
                            if (item._id === action.user._id) return action.user;
                            return item;
                        }),
                        isLoading: undefined
                    }
                } else {
                    return {
                        ...state,
                        results: [
                            ...state.results,
                            action.user
                        ],
                        isLoading: undefined
                    }
                }
            }
        case types.SET_USERS:
            return action.page;
        case types.PASSWORD_UPDATED:
        case types.USER_PROPERTIES_OPTS_FETCHED:
            return {
                ...state,
                isLoading: false
            }
        case types.USER_ERRORS:
        case types.USER_PROPERTIES_OPTS_ERRORS:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    ...action.errors
                },
                isLoading: undefined
            }
        case types.USER_LOADING:
        case types.USER_PROPERTIES_OPTS_LOADING:
            return {
                ...state,
                isLoading: true
            }
        default:
            return state;
    }
}